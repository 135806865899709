import { useState } from "react";
import commonStyles from "../../commonStyles.module.scss";
import styles from "./MediaItems.module.scss";

import { APPROVED, REJECTED } from "../../Constants";
function MediaItems({ item, updateActionOnItem }) {
  const [itemAction, setItemAction] = useState("");
  const [isPicZoomed, setIsPicZoomed] = useState("");
  function approveClicked() {
    setItemAction(APPROVED);
    updateActionOnItem(item.itemId, APPROVED);
  }
  function rejectClicked() {
    setItemAction(REJECTED);
    updateActionOnItem(item.itemId, REJECTED);
  }
  function resetClicked() {
    setItemAction("");
    updateActionOnItem(item.itemId, "");
  }
  function togglePicZoomStatus() {
    console.log("toggled pic zoom");
    if (isPicZoomed === "") {
      setIsPicZoomed("1");
    } else {
      setIsPicZoomed("");
    }
  }
  function getApproveRejectButtonPanel() {
    return (
      <>
        <button
          className={commonStyles.greenButton}
          onClick={approveClicked}
          type="button"
        >
          approve
        </button>
        <button
          className={commonStyles.brownButton}
          onClick={rejectClicked}
          type="button"
        >
          reject
        </button>
      </>
    );
  }
  function getResetButtonPanel() {
    return (
      <button
        className={commonStyles.blueButton}
        onClick={resetClicked}
        type="button"
      >
        reset
      </button>
    );
  }

  return (
    <div className={commonStyles.fieldContainer}>
      <strong>Pic</strong>
      <br />
      <img
        className={`${isPicZoomed ? styles.zoomedImage : styles.normalImage}`}
        src={item.url}
        onClick={togglePicZoomStatus}
        alt="person pic"
      />
      <br />
      <br />
      {itemAction === ""
        ? getApproveRejectButtonPanel()
        : getResetButtonPanel()}
    </div>
  );
}

export default MediaItems;
